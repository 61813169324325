<template>
  <home-base-card
    card_title="セラピスト管理"
    :link_path="link_path"
    icon="mdi-face-woman"
    :loading="loadingFlg"
    :set_color="castCount > 0 ? 'bg_ok' : 'bg_ng'"
    :is_error="isError"
  >
    <div>
      登録<span class="text-h5 text-blue ml-1 mr-1">{{ castCount }}</span
      >名<span class="ml-2"
        >公開中<span class="ml-1 font-bold">{{ enableCount }}</span
        >名</span
      ><span class="ml-2"
        >非公開<span class="ml-1 font-bold">{{ disableCount }}</span
        >名</span
      >
    </div>
  </home-base-card>
</template>

<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import retryRequest from "@/common/retryRequest";

export default {
  props: ["link_path"],
  setup(props, ctx) {
    const castsRepository = repositoryFactory.get("casts");
    const state = reactive({
      loadingFlg: false,
      isAuto: false,
      castCount: 0,
      enableCount: 0,
      disableCount: 0,
      isError: false,
    });

    const getCastList = async () => {
      state.isError = false;
      state.loadingFlg = true;

      const casts = [];
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);

      try {
        const response = await retryRequest(castsRepository.list, params);
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            casts.push(response.data[key]);
          });
        }
      } catch (error) {
        state.isError = true;
      } finally {
        state.loadingFlg = false;
      }

      state.castCount = 0;
      state.enableCount = 0;
      state.disableCount = 0;

      casts.forEach((cast) => {
        state.castCount++;
        if (cast.is_enable) {
          state.enableCount++;
        } else {
          state.disableCount++;
        }
      });
    };

    onMounted(() => {
      getCastList();
    });

    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
